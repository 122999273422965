import {
  AddMultiRoomButton,
  ArrowRoiCarousel,
  EditMultiRoomButton,
  Login,
  ModalConfirmParams,
  SkyBoxMultiroom,
  SkyBoxPrimary,
  BoxComparisonTable,
  SourceJourney,
  SkyGoBanner,
  VTVInformation,
  BoxSelectableCard,
} from '@sky-tv-group/components';
import {
  Box,
  couponStoreApi,
  CouponTypeValue,
  ELIGIBILITY_ACQUISITION_TYPE,
  productSkuIds,
  SPLITIO_KEY,
  useAddUpdateBox,
  useArrowDeliveryStore,
  useboxesStore,
  useCartContainer,
  useCustomerStore,
  useEligibilityOffers,
  useProductStore,
  useSplitIO,
} from '@sky-tv-group/shared';

import React from 'react';
import { ACCOUNT_URL } from '../../config';
import { useAcquisitionJourneyState } from '../../provider/AcquisitionJourney';
import { konakartService } from '../../services';
import { BackButton } from '../../components/back-button';
import history from '../../history';
export const isArrow = (sku: string) => {
  return sku.includes(productSkuIds.arrowBox.primary) || sku.includes(productSkuIds.arrowBoxBlack.primary);
};

export let SkyBox = () => {
  const [showNewSkyBox] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX);
  const [allowMultiroom] = useSplitIO(SPLITIO_KEY.SKYICE_MULTIROOM_ADD);
  const [skyPodForAll] = useSplitIO(SPLITIO_KEY.SKYWEB_SKY_POD_FOR_ALL);
  const [dartPreorderEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_VTV_DART_PREORDER);
  const [isNSBCampaign, NSBconfig] = useSplitIO(SPLITIO_KEY.NSB_POD_CAMPAIGN_CODE_SKIP);
  const nsborPodCampaign = typeof NSBconfig === 'string' && JSON.parse(NSBconfig);

  const { acquisitionType } = useEligibilityOffers();
  const { address } = useCustomerStore();
  const { progressBar } = useAcquisitionJourneyState();
  const { changeProductsByQuantity, getProductAndAddons, swapMultipleProducts } = useCartContainer(
    konakartService,
    CouponTypeValue.Acquisition
  );

  const { boxesFromStore } = useboxesStore(s => ({ boxesFromStore: s.boxes }));
  const { addNewBoxtoStore, removeBoxFromStore } = useAddUpdateBox();
  const { clearArrowDeliveryStore } = useArrowDeliveryStore();

  const subscribedBoxes = boxesFromStore.filter(b => b.boxType === 'NEW');
  const primaryBox = subscribedBoxes.find(b => b.primary);

  const products = useProductStore(s => s.products);
  const skyBoxCharge = products.find(p => p.sku === productSkuIds.skyBoxCharge.primary)!;
  const arrowBox = products.find(p => p.sku === productSkuIds.arrowBox.primary)!;
  let modalProducts = showNewSkyBox ? [arrowBox, skyBoxCharge] : [skyBoxCharge];
  const isVTVStandalone = acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone;

  const skyPod = products.find(p => p.sku === productSkuIds.skyPod.primary)!;
  if (skyPodForAll) {
    modalProducts.splice(1, 0, skyPod);
  }

  // Arrow box fees
  const arrowUpfrontPayment = products.find(p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary);
  const arrowMonthlyPayment = products.find(p => p.sku === productSkuIds.arrowMonthly.primary);

  /* Always offer Sky Pod to VTV Standalone customers. */
  if (isVTVStandalone && !skyPodForAll) {
    modalProducts.splice(1, 0, skyPod);
    // If NSB isn't already included and this is a VTV Standalone, also allow NSB
    if (!modalProducts.find(p => p.sku === arrowBox.sku)) {
      modalProducts.push(arrowBox);
    }
  }

  // Add primary and multiroom box modal props
  const modalProps = {
    address: address,
    products: modalProducts,
    arrowFees: {
      arrowMonthly: arrowMonthlyPayment,
      arrowUpfront: arrowUpfrontPayment,
    },
    confirm:async  (params: ModalConfirmParams) => {
      let { selected, oneoff, recording, editing, primary } = params;
      let product = modalProducts.find(p => p.sku === selected)!;
      let products = getProductAndAddons(product, oneoff, recording, primary);

      addNewBoxtoStore(product.name, 'NEW', products, undefined, primary);

      if(isNSBCampaign){
        const has04998 = couponStoreApi.getState().getCouponFromCode(nsborPodCampaign.nsb_campaign);
        if(!has04998){
          const coupon = await konakartService.getCoupon(nsborPodCampaign.nsb_campaign);
          if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
        }
      }
      if (editing) {
        removeBoxFromStore(editing.id!);
      await  swapMultipleProducts(editing.products, products);
      } else {
       await  changeProductsByQuantity(products);
        clearArrowDeliveryStore();
      }
    },
    cancel: (box: Box) => {
      removeBoxFromStore(box.id!);
      changeProductsByQuantity(box.products, -1);
    },
    source: SourceJourney.WEB_ICE,
  };
  modalProducts = modalProducts.filter(p=> p.sku!=="620");
  return (
    <div>
      <div className="flex flex-col items-center z-20 sticky bg-white shadow top-4 lg:w-full">
        <div className="container m-auto p-2 justify-center lg:pr-20 lg:py-4 lg:flex lg:flex-col xl:flex-row items-center">
          {progressBar(false)}
        </div>
      </div>

      <div className="container m-auto " data-testid="skybox">
        <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start py-10 lg:px-16 md:w-100">
          <BackButton buttontext="Go back to TV" onClick={() => history.push('/tv-packages')} />
        </div>
        <div className="lg:px-16 md:w-100 md:w-full lg:ml-1">
          <div className="max-w-80% mx-auto md:max-w-none md:mx-0 mt-6 lg:mt-0">
            {acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone && (
              <>
                <VTVInformation preOrder={!!dartPreorderEnabled} />
              </>
            )}
            {/* {showNewSkyBox && (
              <div className="pt-4 pb-14">
                <ArrowRoiCarousel />
              </div>
            )} */}

            <SkyBoxPrimary
              heading="Choose your TV Device:"
              box={primaryBox}
              source={SourceJourney.WEB_ICE}
              addBoxButton={() => (
                <AddMultiRoomButton title="Choose your device" modalProps={{ ...modalProps, isPrimary: true }} />
              )}
              editBoxButton={box => (
                <EditMultiRoomButton
                  modalProps={{ editingBox: box, isPrimary: true, ...modalProps, cancel: undefined }}
                />

              )}
              products={modalProducts}
              confirm = {modalProps.confirm}
              changeProductsByQuantity={changeProductsByQuantity}
            />


            <p className='my-6'>If your home lacks an internet connection or your service coverage is poor, please contact our friendly Sky crew on <a href="tel:09 525 5555 " className='text-blue-pure underline text-sm'>09 525 5555 </a> for alternative solutions to enjoy Sky.</p>

            {primaryBox && (
              <div className='w-full flex flex-col gap-4 py-4 border-t'>
                <h3 className='sky-h3-reg font-normal md:sky-h4-reg ml-2'><span className='sky-h3-black font-black md:sky-h4-black'>Add</span> Sky in another room:</h3>
                <p>For every device after your first there is a $25/mth multiroom charge applied (max 5). Additional charges may apply.</p>
                </div>
            )}
            {primaryBox && allowMultiroom && (
              <div className="mb-10 flex flex-col md:flex-row gap-4">
                {/* <SkyBoxMultiroom
                  heading="Add Multiroom Devices:"
                  boxes={boxesFromStore}
                  addMultiroom={() => <AddMultiRoomButton modalProps={{ ...modalProps }} key="AddMultiRoomButton" />}
                  editMultiroom={(box: Box) => <EditMultiRoomButton modalProps={{ editingBox: box, ...modalProps }} />}
                  source={SourceJourney.WEB_ICE}
                /> */}
             { modalProducts.map(box => <BoxSelectableCard isPrimary={false} box={box} confirm={modalProps.confirm } cancel = {modalProps.cancel}/>)}
              </div>
            )}
            {/* {(showNewSkyBox || isVTVStandalone || skyPodForAll) && (
              <BoxComparisonTable
                showNewSkyBox={!!showNewSkyBox}
                showSkyPod={!!skyPodForAll || !!isVTVStandalone}
                showVodafoneTV={false}
                source={SourceJourney.WEB_ICE}
              />
            )}
            <SkyGoBanner /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
