import { ProgressBar } from '@sky-tv-group/components';
import { ELIGIBILITY_ACQUISITION_TYPE, useEligibilityOffers } from '@sky-tv-group/shared';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { formatBreadcrumbs } from '../helper';
import { routes } from '../screens/BuildMyPlan/routes';

export interface AcquisitionJourneyStateInterface {
  progressBar: (isCheckout?: boolean) => JSX.Element | null;
}

const AcquisitionJourneyStateContext = createContext<AcquisitionJourneyStateInterface>({
  progressBar: () => null,
});

const UIAcquisitionJourneyProvider = ({ children }: { children: React.ReactNode }) => {
  const { acquisitionType } = useEligibilityOffers();
  const _routes = routes.filter(r =>
    acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone ? r.path !== '/broadband' : true
  );
  const temproutes = _routes.filter(r => r.path !== '/checkout/saved-cart');
  let crumbs = formatBreadcrumbs(temproutes);
  let location = useLocation();
  const _formatBreadcrumbsForTracking = (isCompleted: boolean) =>
    crumbs.reduce((prev: { [x: string]: boolean }, c) => {
      prev[c.href] = isCompleted;

      return prev;
    }, {});

  const [tabState, setTabState] = useState(_formatBreadcrumbsForTracking(false));

  // Create progress bar component
  const progressBar = (isCheckout?: boolean) => {
    let path = location.pathname;
    // Because tv and add-ons are merged into the same breadcrumb
    if (path === '/build-my-plan/add-ons') path = '/build-my-plan/tv-packages';
    if (path === '/build-my-plan/checkout/saved-cart') path = '/build-my-plan/checkout';
    if (path === '/checkout/dth-form' || path === '/checkout/broadband-form' || path === '/checkout/voice-form') {
      path = '/checkout/dth-form';
    }
    const isBundleThere=sessionStorage.getItem('isBundle');
    return isCheckout ? (
      //@ts-ignore - progress props value warning on local
      <ProgressBar current={4} crumbs={crumbs} progress={_formatBreadcrumbsForTracking(true)} />
    ) : (
      //@ts-ignore - progress props value warning on local
      <ProgressBar current={(isBundleThere? crumbs.filter((p,i)=> i>2 ? p :""):crumbs).findIndex(s => s.href === path)} crumbs={(isBundleThere? crumbs.filter((p,i)=> i>2 ? p :""):crumbs)
    } progress={tabState} />
    );

  };

  useEffect(() => {
    let path = location.pathname;
    let _tabState = { ...tabState };

    // Because tv and add-ons are merged into the same breadcrumb
    if (path === '/build-my-plan/add-ons') path = '/build-my-plan/tv-packages';
    if (path === '/build-my-plan/checkout/saved-cart') path = '/build-my-plan/checkout';
    // Based on the current URL, find the appropriate breadcrumb
    const currentKey = Object.keys(tabState).findIndex(key => key === path);
    const currentCrumb = crumbs.find(c => c.href === `${path}`);

    // If the current crumb is after the first step, make sure to update all the prior steps to clickable too
    if (currentKey + 1 > 1) {
      Array.from({ length: currentKey + 1 }, (_, i) => i).forEach(i => {
        const key = Object.keys(tabState)[i];

        // Don't allow the confirmation page to be clickable
        if (key !== '/checkout/confirm') _tabState[key] = true;
      });
    }

    if (currentCrumb?.clickable) {
      _tabState[currentCrumb.href] = true;

      setTabState(_tabState);
    }
    //eslint-disable-next-line
  }, [location]);

  return (
    <AcquisitionJourneyStateContext.Provider value={{ progressBar }}>
      {children}
    </AcquisitionJourneyStateContext.Provider>
  );
};

const useAcquisitionJourneyState = () => {
  const context = useContext(AcquisitionJourneyStateContext);
  if (!context) {
    throw new Error('useAcquisitionJourneyState must be used within a AcquisitionJourneyStateContext');
  }
  return context;
};

export { UIAcquisitionJourneyProvider, useAcquisitionJourneyState };
