import React from 'react';
import classnames from 'classnames';

export interface UpcomingOfferProps {
  className?: string;
  icon?: string;
  bgColor?: string;
  gradient?: string;
  isUpgradeJourney?: boolean;
  text?: string;
}

const UpcomingOffer = ({
  className = '',
  bgColor,
  gradient = 'linear-gradient(145deg, #363FBC -26.95%, #060606 79.5%)',
  icon = 'https://static.sky.co.nz/sky/icons/StampSave15.png',
  isUpgradeJourney,
  text,
}: UpcomingOfferProps) => {
  return (
    <div
      className={classnames(
        'flex flex-col justify-start items-center m-auto w-full md:flex md:flex-row py-4 px-4 md:px-10 gap-10 banner-border',
        className
      )}
      style={{ background: bgColor }}>
      <img alt={'half price'} src={icon} className="w-28 md:w-32" />
      {!isUpgradeJourney ? (
        <div>
          <h3 className="sky-h6-bold md:sky-h6-bold font-black sky-text-midnight">
          Bundle Sky TV and Sky Broadband and save up to an additional $15/month for the first 12 months on a Sky Broadband Fibre Plan.{' '}
          </h3>
          {/* <span className="sky-text-midnight sky-h7-reg offer-banner-description">
            Join Sky TV OR Sky Broadband and get 3 months half price. 12 month contract, exit fees and{' '}
          </span> */}
          <a
            href="https://www.sky.co.nz/offer-terms "
            className="underline sky-text-midnight sky-h7-reg offer-banner-description">
            T&Cs
          </a>{' '}
          <span className="sky-text-midnight sky-h7-reg offer-banner-description">apply.</span>
        </div>
      ) : (
        <div>
          <span className="sky-text-white sky-h7-reg md:sky-h6-reg font-black">
            Bundle Sky TV and Sky Broadband and save up to an additional $15/month for the first 12 months on a Sky Broadband Fibre Plan
          </span>
          <br />
          <span className="sky-text-white sky-h7-reg md:sky-h6-reg font-normal">{text}</span>
          <a
            href="https://www.sky.co.nz/offer-terms "
            className="underline sky-text-white sky-h7-reg md:sky-h6-reg font-normal">
            T&Cs
          </a>{' '}
          <span className="sky-text-white sky-h7-reg md:sky-h6-reg font-normal">apply.</span>
        </div>
      )}
    </div>
  );
};

export { UpcomingOffer };
