/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  T_Product, bbNameInterface, T_PlanSelector, productSkuIds, useToastContainer, useCartContainer, useSplitIO, SPLITIO_KEY, IPopUpContentConfig, useJSONConfig,
  useBBname, productStoreApi,
  useProductStore,
  redirectToSubdomain,
  useNewAcquisitionOrderStore,
  orderStoreApi,
  categoryIds,
  segment
} from '@sky-tv-group/shared';

// components
import { BlockLoader, GenericProductCard, Unchecked, Checked, } from '@sky-tv-group/components';
import { isBrowser, isTablet, isMobile } from 'react-device-detect';
import { useChoosePlan } from '../../hooks/useChoosePlan';
import { Plan } from './Plan';
import { isPlanSelectedBasedOnSku } from '../../helper';
import { ProductCardFragment } from '@sky-tv-group/graphql';
import { configService, kkService } from './../../services/index';
import Modal from 'react-modal'
import { Addons } from './Addons';
import { useCheckYourAddress } from '../../hooks/useCheckYourAddress';

interface PlanSelectorProps {
  // hasMeshDevice: boolean;
  // broadbandProducts: T_Product[];
  isStarterInCart: boolean | undefined;
  onSelectChange: (product: T_Product, isBundle: boolean) => void;
  // isUserAllowedToContinue?: boolean;
  canPurchaseBroadband?: boolean;
  // planArr: T_PlanSelector[];
  planArr: MoreChoicesForYouProduct[];
}

export interface MoreChoicesForYouProduct {
  product: ProductCardFragment;
  kkProduct?: T_Product;
}

const wireUpProductInfo = (products: T_Product[], bbName: bbNameInterface) => {
  // console.log(products,"---products-->")
  const plan1g = products.find(p => p.sku === productSkuIds.broadbandLightningFastWiFi.primary);
  const plan100m = products.find(p => p.sku === productSkuIds.broadbandWifi100.primary);
  const discount1g = products.find(p => p.sku === productSkuIds.broadbandStarterDiscountOneGig.primary);
  const discount100m = products.find(p => p.sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary);
  const planFibreStarter = products.find(p => p.sku === productSkuIds.broadbandEssnFibre.primary);
  const starters = products.find(p => p.sku === productSkuIds.starter.primary);

  if (!plan1g || !plan100m || !discount1g || !discount100m || !starters || !planFibreStarter) {
    return;
  }

  const data: T_PlanSelector[] = [
    {
      index: 0,
      product: plan1g,
      header: {
        title: bbName.broadbandLightningFastWiFi.label,
        subtitle: plan1g.custom8 ?? '',
      },
      sku: plan1g.sku,
      price: {
        name: bbName.broadbandLightningFastWiFi.label,
        fullPrice: `${plan1g.price1}`,
        nonStarterPrice: `${plan1g.priceIncTax}`,
        term: 'On a 12 month contract',
      },
      features: ['<strong>Unlimited</strong> fibre up to <strong>900Mbps</strong>'],
      bundle: false,
      bestPlan: true,
      imgSrc: 'https://static.sky.co.nz/sky/broadband/img/fibrepro.png',
      exploreImg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/lfw.png',
    },
    {
      index: 1,
      product: discount1g,
      header: {
        title: bbName.broadbandLightningFastWiFi.label,
        subtitle: '',
      },
      sku: discount1g.sku,
      price: {
        name: bbName.broadbandLightningFastWiFi.label,
        fullPrice: `${starters.priceIncTax + plan1g.price1}`,
        nonStarterPrice: `$${plan1g.priceIncTax}`,
        term: 'On a 12 month contract',
        discount: `${discount1g.priceIncTax}`,
        discountedPrice: `${plan1g.price1}`,
      },
      features: [
        '<strong>Unlimited</strong> fibre up to <strong>900Mbps</strong>',
        `<strong>1 month Sky TV</strong> on us`,
      ],
      bundle: true,
      bestPlan: true,
      imgSrc: 'https://static.sky.co.nz/sky/broadband/img/fibrepro.png',
      exploreImg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/lfw.png',
    },
    {
      index: 2,
      product: discount100m,
      header: {
        title: bbName.broadbandWifi100.label,
        subtitle: '',
      },
      sku: discount100m.sku,
      price: {
        name: 'Our entry level fibre plan',
        fullPrice: `${starters.priceIncTax + plan100m.price1}`,
        nonStarterPrice: `$${plan100m.priceIncTax}`,
        term: 'On a 12 month contract',
        discount: `${discount100m.priceIncTax}`,
        discountedPrice: `${plan100m.price1}`,
      },
      features: [
        '<strong>Unlimited</strong> fibre up to <strong>300Mbps</strong>',
        `<strong>1 month Sky TV</strong> on us`,
      ],
      bundle: true,
      imgSrc: 'https://static.sky.co.nz/sky/broadband/img/fibreeveryday.png',
      exploreImg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/wifi100.png',
    },
    {
      index: 3,
      product: plan100m,
      header: {
        title: bbName.broadbandWifi100.label,
        subtitle: plan100m.custom8 ?? '',
      },
      sku: plan100m.sku,
      price: {
        name: 'Our entry level fibre plan',
        fullPrice: `${plan100m.price1}`,
        nonStarterPrice: `${plan100m.priceIncTax}`,
        term: 'On a 12 month contract',
      },
      features: ['<strong>Unlimited</strong> fibre up to <strong>300Mbps</strong>'],
      bundle: false,
      imgSrc: 'https://static.sky.co.nz/sky/broadband/img/fibreeveryday.png',
      exploreImg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/wifi100.png',
    },

    {
      index: 4,
      product: planFibreStarter,
      header: {
        title: bbName.broadbandStarterWiFi.label,
        subtitle: planFibreStarter.custom8 ?? '',
      },
      sku: planFibreStarter.sku,
      price: {
        name: 'Our entry level fibre plan',
        fullPrice: `${planFibreStarter.price1}`,
        nonStarterPrice: `${planFibreStarter.priceIncTax}`,
        term: 'On a 12 month contract',
      },
      features: ['<strong>Unlimited</strong> fibre up to <strong>50Mbps</strong>'],
      bundle: false,
      imgSrc: 'https://static.sky.co.nz/sky/broadband/img/fibrestarter.png',
      exploreImg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/New/wifi100.png',
    },
  ];
  return data;
};



const CardPlanSelector = ({ onSelectChange, isStarterInCart, canPurchaseBroadband, planArr}: PlanSelectorProps) => {

  const { selectedAddressId, selectedTuiAddress } = useNewAcquisitionOrderStore(s => ({
    selectedAddressId: s.selectedAddressId,
    selectedTuiAddress: s.selectedTuiAddress,
  }));
  const { lfwProduct, wifi100Product, broadbandProductInCart, fibreStarterProduct } = useChoosePlan();
  const { addToast } = useToastContainer();
  const { starter, starterAdded } = useCartContainer(kkService, 'ACQUISITION');
  const [isBBAdded, setBBAdded] = React.useState(false);

  const isBBInCart = orderStoreApi.getState()?.order?.orderProducts?.find(p => p.categoryId === categoryIds?.broadband);
  const kkProducts = productStoreApi.getState().products;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const isBBPlansSkus =isBBInCart?.product?.custom9 ?? isBBInCart?.product?.sku

  const bbName = useBBname();
  const rollingTermsContent = (isStarter: boolean) => {
    let copy = {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'On a rolling monthly plan.Offer T&Cs apply.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    };

    return copy;
  };
  const focusOnAddressSelector = () => {
    const addressSelectorInputField = document.getElementById('address');
    if (addressSelectorInputField) {
      addressSelectorInputField.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      addressSelectorInputField.focus({ preventScroll: true });
    }
  };

  const config = useJSONConfig(configService);
  const [skySportPriceChangePopUpEnable] = useSplitIO(SPLITIO_KEY.SKYWEB_SKY_PRICE_CHANGE_POPUP);
  const skySportsPriceChangeResponse: IPopUpContentConfig[] = config?.popUps?.priceChanges?.content;
  const skySportsPriceChangeData = skySportPriceChangePopUpEnable ? skySportsPriceChangeResponse : undefined;
  const [allowRedirectToIce] = useSplitIO(SPLITIO_KEY.REDIRECT_BB_BUNDLES_TO_ICE);
  const { isFibreOk } = useCheckYourAddress();
  // const { setSelectedCardSku, isUpdatingPlan } = useNewAcquisitionOrderStore();
  const { setUpdatedValue, isUpdatingPlan } = useNewAcquisitionOrderStore();
  const [selectedPlan, setSelectedPlan] = React.useState<any>()

  // Select broadband product based on if user has booster/mesh in their cart
  const handleCardSelect = (sku: string, isBundle: boolean) => {
    // openModal();
    const selectedBBProduct = planArr.find(p => p.kkProduct?.sku === sku);
    setSelectedPlan(selectedBBProduct);
    // const selectedBBProduct = planArr.find(p => p.kkProduct?.sku === sku);
    let bundleName: string = '';
    if (isFibreOk()) {
      setIsOpen(true);
    }

    // if (isBundle) {
    //   switch (sku) {
    //     case productSkuIds.broadbandStarterDiscountOneGig.primary:
    //       bundleName = 'bb_pro_bundle';
    //       break;
    //     case productSkuIds.broadbandStarterDiscountHundredMeg.primary:
    //       bundleName = 'bb_everyday_bundle';
    //       break;
    //     // No SKU for the new starter bundle, so using public dictionary name
    //     case 'bb_starter_bundle':
    //       bundleName = 'bb_starter_bundle';
    //       break;
    //   }
    // }

    if (canPurchaseBroadband) {
      if (isBundle && allowRedirectToIce) {
        redirectToSubdomain('ICE', `?bundle=${bundleName}&addressId=${selectedAddressId ?? ''}`, false);
      } else {
        selectedBBProduct?.kkProduct ? setBBAdded(true) : setBBAdded(false)
        setUpdatedValue(sku);
        // onSelectChange(selectedBBProduct?.kkProduct!, isBundle);
      }
    } else {
      focusOnAddressSelector();
    }
  };

  if (!planArr) return <BlockLoader />;



  const plans = starter ? wireUpProductInfo([...kkProducts, starter], bbName) : wireUpProductInfo(kkProducts, bbName);
  const getExploreProductByPlanSku = (sku: string) => {
    if (sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary) {
      return plans?.find(p => p.sku === productSkuIds.broadbandStarterDiscountHundredMeg.primary);
    } else if (sku === productSkuIds.broadbandStarterDiscountOneGig.primary) {
      return plans?.find(p => p.sku === productSkuIds.broadbandStarterDiscountOneGig.primary);
    } else if (sku === 'bb_starter_bundle') {
      // In the kk there is no sku for bb_starter_bundel, that's why we need to hard code the price here for fiber starter with sky tv.
      let fiberStarterPlan = plans?.find(p => p.sku === productSkuIds.broadbandEssnFibre.primary);
      if (fiberStarterPlan) {
        fiberStarterPlan.price.nonStarterPrice = '85.99';
        fiberStarterPlan.price.fullPrice = '85.99';
      }
      return fiberStarterPlan;
    }
    return plans?.find(p => p.sku === sku);
  };

  const _handleDiscountedPrices = (product: ProductCardFragment): any => {
    const _plan = plans?.find(p => p.product?.sku === product.sku);
    if (!_plan) return Number(product.acquistionOfferPrice);

    return Number(product.acquistionOfferPrice);
    // return isStarterInCart
    //   ? Number(product.upgradeOfferPrice)
    //   : Number(product.acquistionOfferPrice);
    // return product.type === 'Bundle'
    //   ? Number(product.acquistionOfferPrice)
    //   : isStarterInCart
    //   ? Number(product.upgradeOfferPrice)
    //   : Number(product.acquistionOfferPrice);
  };

  //new UI changes
  const widthClass = isMobile && !isBrowser && isTablet && window.innerHeight > window.innerWidth ? 'w-3/5' : 'w-full';
  // Workaround Show column in portrait and row in landscape/desktop :(
  const flexClass = isMobile && window.innerHeight > window.innerWidth ? 'flex-col items-center' : 'flex-row';


  // Nasty iPad workaround
  // const widthClass = isMobile && !isBrowser && isTablet && window.innerHeight > window.innerWidth ? 'w-3/5' : 'w-full';
  // Ever nastier workaround to change array order for all portrait modes
  // const portraitPlanArr = [planArr[1], planArr[2], planArr[0], planArr[3]];
  // Change order if its portrait or landscape
  // const plans = window.innerHeight > window.innerWidth ? portraitPlanArr : planArr;


  const handleDescriptionList = (product: ProductCardFragment) => {
    const _plan = plans?.find(p => p.product?.sku === product.sku);
    // let price = isStarterInCart ? _plan?.price.fullPrice : _plan?.price.nonStarterPrice;
    let price = _plan?.price.nonStarterPrice;
    let listItem = `Then $${price}/mnth`;
    if (!product.detailsList) return product.detailsList;
    const updateArray = [...product.detailsList];
    return updateArray;
  };

  // let close = () => {
  //   setIsOpen(false);
  // };


  return (

    <div className={`container ${widthClass} mx-auto flex justify-center ${flexClass}`}>
      {modalIsOpen && (<Modal isOpen={modalIsOpen}> <div className="rounded-none"  id="overlay"  >
        <Addons setIsOpen={setIsOpen} isBBAdded={isBBAdded} selectedBBProduct={selectedPlan} planArr={planArr} isBBInCart={isBBInCart} />
      </div>
      </Modal>)}
      {planArr
        .sort((a, b) => _handleDiscountedPrices(b.product) - _handleDiscountedPrices(a.product))
        .map(plan => {
          return (
            <div className="mx-3 flex py-sky-md">
              <GenericProductCard
                disabled={false}
                // disabled={!isFibreOk()}
                kkProduct={plan.kkProduct}
                kkService={kkService}
                fixedWidth={330}
                inCart={isBBPlansSkus === plan?.kkProduct?.sku ?true:false}
                isStarterInCart={starterAdded}
                configService={configService}
                plan={getExploreProductByPlanSku(plan.product.sku)}
                skyPriceChangePopUp={skySportsPriceChangeData}

                handleProductCardCTA={async () => handleCardSelect(plan.product.sku, false)}
                selectedTuiAddress={selectedTuiAddress}
                card={{
                  bgColor: plan.product.bgColor,
                  occurrenceType: plan.product.occurrenceType,
                  billingFrequency: plan.product.priceFrequencyType,
                  discountText: plan.product.discountText,
                  imageSrc: {
                    text: plan.product.thumbnail?.text ?? '',
                    url: plan.product.thumbnail?.url ?? '',
                  },
                  lozengeText: plan.product.lozengeText,
                  sku: plan.product.sku,
                  subtitle: plan.product.description,
                  discountedPrice: _handleDiscountedPrices(plan.product),
                  title: plan.product.title,
                  type: plan.product.type,
                  coupon: null,
                  termsAndConditions: plan.product.termsAndConditionsCaption,
                  descriptionList: handleDescriptionList(plan.product),
                  copyrightText: plan.product.copyrightText,
                  iconList: plan.product.iconList,
                  ctaName: isBBPlansSkus === plan?.kkProduct?.sku ? "Customise" : "Add"
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export { CardPlanSelector };