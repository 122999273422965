import React, { useEffect, useState } from 'react';
import { Login, OneMonthOnUs, UpcomingOffer,BlockLoader, GenericProductCard } from '@sky-tv-group/components';
import { ACCOUNT_URL, BUNDLE_OFFER_BANNER } from '../../../config';
import { Faq } from '../../../components/faq';
import { InitiatedContainer } from './Initiated';
import { MarketingContainer } from './Marketing';
import {
  CouponTypeValue,
  T_PlanSelector,
  T_Product,
  useCartContainer,
  ServiceStatus,
  productStoreApi,
  useProductWithCouponRemoval,
  useRemoveProduct,
  useSplitIO,
  SPLITIO_KEY,
  useBBname,
  useNewAcquisitionOrderStore,
} from '@sky-tv-group/shared';
import { konakartService, myAccountV2SWR } from '../../../services';
import { useChoosePlan } from '../../../hooks/useChoosePlan';
import { wireUpProductInfo } from '../../../components/choose-plan/broadband-plans';
import { CardPlanSelector,MoreChoicesForYouProduct } from '../../../components/choose-plan/CardPlanSelector';
import { CheckYourAddress } from '../../../components/check-your-address';
import { useCheckYourAddress } from '../../../hooks/useCheckYourAddress';
import { Important } from '../../../components/important/index';
import { useAcquisitionJourneyState } from '../../../provider/AcquisitionJourney';
import { BackButton } from '../../../components/back-button';
import history from '../../../history';
import { BreadcrumbContainer } from '../../../components/BreadcrumbContainer';
import { GetSkyOfferBanner } from '../../../components/getskyofferbanner';
import { isBrowser, isTablet, isMobile } from 'react-device-detect';

export let Broadband = () => {
  const sectionName = BUNDLE_OFFER_BANNER;
  const { starter, starterAdded } = useCartContainer(konakartService, 'ACQUISITION');
  const { broadbandProductInCart, selectProduct, getSkyBroadbandAction, broadbandProducts } = useChoosePlan();
  const { progressBar } = useAcquisitionJourneyState();
  const [showOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);
  const [BBCreditOfferFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);
  const [show25offer] = useSplitIO(SPLITIO_KEY.SKYWEB_25_OFF_CAMPAIGN);
  const [showBundleOfferBanner] = useSplitIO(SPLITIO_KEY.SKYWEB_BUNDLE_OFFER_BANNER);

  const bbName = useBBname();

  const { setIsUpdatingPlan } = useNewAcquisitionOrderStore();
  const { handleRemovalOfT_OrderProductWithToast } = useProductWithCouponRemoval(konakartService);
  const { toggleCartProduct } = useRemoveProduct(konakartService, CouponTypeValue.Acquisition);

  const [planArr, setPlanArr] = useState<T_PlanSelector[]>();
  const { isFibreOk } = useCheckYourAddress();
  const [isAdding, isAddingSet] = useState(false);
  const { data: bannerData, isValidating } = myAccountV2SWR.useGetComponentCardSectionGQL(false, sectionName);

  const widthClass = isMobile && !isBrowser && isTablet && window.innerHeight > window.innerWidth ? 'w-3/5' : 'w-full';
  const flexClass = isMobile && window.innerHeight > window.innerWidth ? 'flex-col items-center' : 'flex-row';
  const userInfo = myAccountV2SWR.useUserInformationGQL();
  const choices = myAccountV2SWR.useGetMoreChoicesForYou();

  let broadbandProductss = (choices?.data?.productsCards ?? [])
    .filter(pkg => pkg.occurrenceType === 'Data')
    .filter(
      pkg =>
        !userInfo?.data?.billing?.products.some(
          product => product.serviceStatus === ServiceStatus.Active && product.sku === pkg.sku
        )
    );

  const availableBBProducts: MoreChoicesForYouProduct[] = broadbandProductss.reduce(
    (prev: MoreChoicesForYouProduct[], curr) => {
      const kkProduct = productStoreApi.getState().products.find(p => p.sku === curr.sku);
      if (!userInfo?.data.billing.broadbandOnlyAccount) {
        prev.push({
          product: curr,
          kkProduct,
        });
      }
      return prev;
    },
    []
  );
  useEffect(() => {
    if (isAdding && broadbandProductInCart) {
      document.getElementById('booster-title')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    // eslint-disable-next-line
  }, [broadbandProductInCart?.productId, isAdding]);

  const onSelectPlan = async (product: T_Product) => {
    if (product) {
      if (broadbandProductInCart) {
        /* If broadband is already in the cart, means we're removing it. */
        // eslint-disable-next-line
        if (broadbandProductInCart.product.id === product.id) {
          isAddingSet(false);
          setIsUpdatingPlan(true);
          await handleRemovalOfT_OrderProductWithToast({ name: product.name, id: product.id }, toggleCartProduct, true);
          setIsUpdatingPlan(false);
        } else {
          await selectProduct(product, starterAdded);
          isAddingSet(true);
        }
      } else {
        getSkyBroadbandAction(product, starterAdded);
        isAddingSet(true);
      }
    }
  };

  useEffect(() => {
    if (starter) setPlanArr(wireUpProductInfo([...broadbandProducts, starter], bbName, showOneMonthOnUs));
    // eslint-disable-next-line
  }, [bbName]);

  /**
   * TODOs:
   * - Add the Add/Remove capability to the button
   * - Update 'PackageBuy' component with mobile UI
   * - Update 'Plan' component to reflect new design and clean images
   */

  return (
    <div>
      <BreadcrumbContainer progressBarStatus={false} />
      <div className="container m-auto" data-testid="broadband">
        <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start py-10 lg:px-16 md:w-100">
          <BackButton buttontext="Go back to Device" onClick={() => history.push('/build-my-plan/sky-box')} />
        </div>
        <div className="lg:px-16 md:w-100 md:w-full lg:ml-1">
          <div className="mx-4 mb-10 md:m-0">
          {/* { showBundleOfferBanner && bannerData && (<GetSkyOfferBanner bannerData={bannerData} />)} */}
            { show25offer
              && (<UpcomingOffer className="flex w-325 md:w-920" bgColor={'#fff'} isUpgradeJourney={false} />)}
          </div>

          <div className="mb-6 mx-3 mt-3 md:mx-0">
            <span className="sky-h3-black md:sky-h4-black text-navy">Add</span>
            <span className="sky-h3-reg md:sky-h4-reg text-navy ml-2">Unlimited Fibre Broadband to your home:</span>
          </div>
          <div className="pb-5">
            <CheckYourAddress />
            {/* {!broadbandProductInCart && <Important />} */}
            {planArr && (
              <CardPlanSelector
                isStarterInCart={starterAdded}
                onSelectChange={onSelectPlan}
                // planArr={planArr}
                planArr={availableBBProducts.filter(p => {
                  return p.product.type === 'Acquisition';
                })}
                // planArr={planArr}
                canPurchaseBroadband={isFibreOk()}
              />
            )}
             <p className="sky-sub md:sky-sub p-3">
            Fibre Pro and Fibre Everyday speeds shown are based on our network provider's average peak time speeds shown in the Commerce Commission MBNZ Report 21, September 2024. Your speeds may vary especially over WiFi. Factors that can affect your speed include the time of day, where you live, the location and quality of your router and devices and the number of devices being used.
              <a
                className="text-blue-light underline"
                href="https://help.sky.co.nz/s/article/How-fast-is-it-really"
                target="_blank"
                rel="noopener noreferrer">
                &nbsp;Learn more.
              </a>
            </p>
            {/* {broadbandProductInCart ? <InitiatedContainer /> : <MarketingContainer />} */}
            <div className="py-4 px-4 md:px-0">
              <Faq filter={'Marketing'} title={'Important Information'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
