/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { routes, skyAppsRoutes } from './routes';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { categoryIds, YourDetails } from '@sky-tv-group/shared';
import _ from 'lodash';
import { useIdleTimer } from 'react-idle-timer';
import {
  useCustomerStore,
  useProductStore,
  useOrderStore,
  orderStoreApi,
  productStoreApi,
  couponStoreApi,
  pageErrorMessage,
  useSplitIO,
  SPLITIO_KEY,
  initFC,
  disableFC,
  useFreeProduct,
  ICE_URL,
  parseQuery,
  productSkuIds,
  couponCodes,
  useUrlParamsStore,
  useAddUpdateBox,
  useEligibilityOffers,
  ELIGIBILITY_ACQUISITION_TYPE,
  T_Coupon,
  useboxesStore,
  BUNDLES,
  useQueryAddToCart,
  CouponTypeValue,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ,
  useQueryState,
  CreditCampaignCodes,
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US,
  useAccountDetailsStore,
  useAccountDetails,
  newAcquisitionOrderStoreApi,
  useNewAcquisitionOrderStore,
} from '@sky-tv-group/shared';

import {
  NotificationManager,
  Header,
  Footer,
  IEBanner,
  ErrorContainer,
  BlockLoader,
  Maintenance,
  ToastContainer,
  MenuItemsType,
} from '@sky-tv-group/components';

import { useNavStore } from './store/navStore';
import { konakartService, myAccountService, myAccountSWR, myAccountV2SWR, addressService } from './services';
import { LIFERAY_CONNECT_ENDPOINT, DOMAIN } from './config';
import { useAuth0 } from './store/authStore/authStore';
import history from './history';
import { Checkout } from './screens/Checkout';
import { Confirmation } from './screens/Confirmation';
import { useConsumeQueryAddressId } from './hooks/useConsumeQueryAddressId';
import { TermsAndConditionScreen } from './screens/TermsAndConditions';
import { orderService } from '../src/services';
import { CartSummary } from './components/CartSummary';
import { T_OrderRequest } from '@sky-tv-group/shared/src/types';

let Body = () => {
  const [BBCreditOfferFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);
  const [BBOneMonth] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);
  const [arrowCreditOfferEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_ARROW_CREDIT_OFFER);
  const [arrowEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX);
  const location = useLocation();
  const [bundleInURL, bundleInURLSet] = useState(location.search.includes('bundle='));
  const [skusInURL, skusInURLSet] = useState(location.search.includes('skus='));
  const [errorPresent, setErrorPresent] = useState(false);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [orderLoadedWithSports, setOrderLoadedWithSports] = useState(false);
  const { setSelectedSrc } = useUrlParamsStore();
  const { kk: customerId, getTempCustomerId, setActiveGASession, setAddressId, setAddress } = useCustomerStore();
  const { loadProductStore, initializedProductStore, updateFromOrderProducts, products } = useProductStore();
  const {
    initializedOrderStore,
    loadOrderStore,
    updateOrder,
    order,
    orderRequest,
    updateOrderforAbandoned,
  } = useOrderStore();
  const { accountDetails, setAccountDetails } = useAccountDetailsStore();
  const { queryLoadSkus, queryLoadCoupons } = useQueryAddToCart(konakartService, CouponTypeValue.Acquisition);
  const { toggleCheckFreeProductOnLoad } = useFreeProduct(konakartService);
  const { addPrimaryBoxFromOrder, removeBoxFromStore } = useAddUpdateBox();
  const { acquisitionType, eligibilityOfferData } = useEligibilityOffers();
  const [isemailInURL, setEmailInUrl] = useState(location.search.includes('RefId='));
  // Terms and Condition Menu
  const { data: menuListData, isValidating: isTermsCondtionLoading } = myAccountV2SWR.useGetTermsAndConditionMenuGQL();
  const termsConditionData = menuListData?.data?.MenuSectionList?.[0]?.sectionLinks;
  const termsConditionMenu = (termsConditionData ?? []).map(({ name, itemUrl }) => ({
    slug: itemUrl,
    text: name,
  }));
  const GetRefId = () => {
    const search = useLocation().search;
    return new URLSearchParams(search).get('RefId');
  };

  const RefIdfromUrl = GetRefId();
  const { setUpdatedValue } = useNewAcquisitionOrderStore();
  const { updateBoxProducts } = useboxesStore(s => ({
    updateBoxProducts: s.updateBoxProducts,
  }));
  const { setPublicEligibilityOffers } = useCustomerStore(s => ({
    setPublicEligibilityOffers: s.setPublicEligibilityOffers,
  }));
  const { changeProductQuantity } = useProductStore(s => ({
    changeProductQuantity: s.changeProductQuantity,
  }));
  const [isAbandonedCartLoaded, setAbandonedCartLoaded] = useState(false);
  const [iscartDataEmpty, setCartDataEmpty] = useState(false);
  const { addMultipleBoxesforAbandoned } = useboxesStore();
  const { boxesFromStore } = useboxesStore(s => ({ boxesFromStore: s.boxes }));
  const boxeswithoutproduct = boxesFromStore.filter(box => box.products.length === 0);
  const hasdthonly = couponStoreApi.getState().coupons.find(c => c.couponCode === couponCodes.offer50WOShohoHindi);
  const hasdthBundle = couponStoreApi.getState().coupons.find(c => c.couponCode === couponCodes.bundleCoupon_dth);
  const hasBbBundle = couponStoreApi.getState().coupons.find(c => c.couponCode === couponCodes.bundlecoupon_BB);
  const has01293 = couponStoreApi.getState().coupons.find(c => c.couponCode === couponCodes.offer50WShohoHindi);
  const has04998 = couponStoreApi
    .getState()
    .coupons.find(c => c.couponCode === NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US);
  const hasSohoHindiBundle = couponStoreApi
    .getState()
    .coupons.find(c => c.couponCode === couponCodes.bundlecoupon_soho_Hindi);
  const hindi = order?.orderProducts.find(p => p.categoryId === categoryIds.hindiChannels);
  const sohoInCart = order?.orderProducts.find(p => p.product.sku === productSkuIds.soho.primary);

  const fibrePro = order?.orderProducts.find(p => p.product.sku === productSkuIds.broadbandLightningFastWiFi.primary);
  const isBroadbandProduct = order?.orderProducts.find(p => p.categoryId === categoryIds.broadband);

  const fibreProBoost = order?.orderProducts.find(
    p => p.product.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary
  );
  const fibreStarter = order?.orderProducts.find(p => p.product.sku === productSkuIds.starter.primary);
  const fibreEveryday = order?.orderProducts.find(p => p.product.sku === productSkuIds.broadbandWifi100.primary);
  const fibreEverydayBoost = order?.orderProducts.find(
    p => p.product.sku === productSkuIds.broadbandWifi100Boost.primary
  );
  const arrowUpfront = order?.orderProducts.find(p => p.product.sku === productSkuIds.arrowUpfrontBoxFee.primary);
  let freeNSB = order?.orderProducts.find(p => p.product.sku === productSkuIds.arrowUpfrontBoxFee.primary);
  let freePOD = order?.orderProducts.find(p => p.product.sku === productSkuIds.skyPodOneOffFee.primary);
  const [isloading, setisLoading] = useState(false);
  const [cartData, setCartData] = useState();
  useEffect(() => {
    const getCartData = async () => {
      try {
        const response = RefIdfromUrl ? await orderService.getAbandonedCartData(RefIdfromUrl) : '';
        setCartData(response);
      } catch {
      } finally {
        setisLoading(true);
      }
    };
    if (RefIdfromUrl) getCartData();
  }, [RefIdfromUrl]);
  useEffect(() => {
    if (acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone || !orderLoaded) return;
    const loadOneOffCoupon = async () => {
      if ((freeNSB && freeNSB?.quantity > 0) || (freePOD && freePOD.quantity > 0)) {
        if (!has04998) {
          const coupon = await konakartService.getCoupon(NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US);
          if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
          await updateOrder(
            konakartService,
            customerId,
            productStoreApi.getState().getBasketItemsToAddToOrder(),
            couponStoreApi.getState().coupons
          );
          updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
        }
      } else {
        if (has04998) {
          couponStoreApi.getState().clearCouponFromCode(NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US);
          await updateOrder(
            konakartService,
            customerId,
            productStoreApi.getState().getBasketItemsToAddToOrder(),
            couponStoreApi.getState().coupons
          );
          updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
        }
      }
    };
    loadOneOffCoupon();
  }, [freeNSB?.quantity, freePOD?.quantity, orderLoaded]);
  useEffect(() => {
    const updateOrderOnSohoHindiQtyChange = async () => {
      if (acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone || !orderLoaded) return;
      if ((hindi && hindi?.quantity > 0) || (sohoInCart && sohoInCart?.quantity > 0)) {
        if (!(isBroadbandProduct && isBroadbandProduct?.quantity > 0)) {
          if (!has01293) {
            // const coupon2 = has01293 ?? (await konakartService.getCoupon(couponCodes.offer50WShohoHindi));
            // if (coupon2) {
            //   couponStoreApi.getState().clearCouponFromCode(couponCodes.bundlecoupon_soho_Hindi);
            //   couponStoreApi.getState().applyCouponToStore(coupon2);
            // }
            // await updateOrder(
            //   konakartService,
            //   customerId,
            //   productStoreApi.getState().getBasketItemsToAddToOrder(),
            //   couponStoreApi.getState().coupons
            // );
          }
        } else {
          // if (!hasSohoHindiBundle) {
          //   const coupon2 =
          //     hasSohoHindiBundle ?? (await konakartService.getCoupon(couponCodes.bundlecoupon_soho_Hindi));
          //   if (coupon2) {
          //     couponStoreApi.getState().clearCouponFromCode(couponCodes.offer50WShohoHindi);
          //     couponStoreApi.getState().applyCouponToStore(coupon2);
          //   }
          //   await updateOrder(
          //     konakartService,
          //     customerId,
          //     productStoreApi.getState().getBasketItemsToAddToOrder(),
          //     couponStoreApi.getState().coupons
          //   );
          // }
        }
        updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
      }
      setOrderLoadedWithSports(true);
    };
    updateOrderOnSohoHindiQtyChange();
  }, [hindi?.quantity, orderLoaded, sohoInCart?.quantity, isBroadbandProduct?.quantity]);

  // useEffect(() => {
  //   if (acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone || !orderLoaded) return;
  //   const updateOrderOnSohoHindiRemoval = async () => {
  //     if (has01293 || hasSohoHindiBundle) {
  //       if (hindi?.quantity === undefined && sohoInCart?.quantity === undefined) {
  //         //const coupon = has01293 ?? (await konakartService.getCoupon(couponCodes.offer50WShohoHindi));

  //         couponStoreApi.getState().clearCouponFromCode(couponCodes.offer50WShohoHindi);
  //         couponStoreApi.getState().clearCouponFromCode(couponCodes.bundlecoupon_soho_Hindi);
  //         await updateOrder(
  //           konakartService,
  //           customerId,
  //           productStoreApi.getState().getBasketItemsToAddToOrder(),
  //           couponStoreApi.getState().coupons
  //         );
  //         updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
  //       }
  //     }
  //   };
  //   updateOrderOnSohoHindiRemoval();
  // }, [hindi?.quantity, orderLoaded, sohoInCart?.quantity]);

  useEffect(() => {
    const skyStarter = order?.orderProducts.some(p => p.product.sku === productSkuIds.starter.primary);
    const loadCoupons = async () => {
      if (acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone || !orderLoaded) return;
      if (BBCreditOfferFlag) {
        let coupons = Object.values(CreditCampaignCodes.BROADBAND);
        coupons.forEach(coupon => {
          couponStoreApi.getState().clearCouponFromCode(coupon);
        });
        if (isBroadbandProduct) {
          const coupon2 = hasBbBundle ?? (await konakartService.getCoupon(couponCodes.bundlecoupon_BB));
          if (coupon2) couponStoreApi.getState().applyCouponToStore(coupon2);
          if (hasdthonly || !hasBbBundle || !hasdthBundle) {
            if (((hindi && hindi?.quantity > 0) || (sohoInCart && sohoInCart?.quantity > 0)) && !hasSohoHindiBundle) {
              // const coupon2 =
              //   hasSohoHindiBundle ?? (await konakartService.getCoupon(couponCodes.bundlecoupon_soho_Hindi));
              // if (coupon2) {
              //   couponStoreApi.getState().clearCouponFromCode(couponCodes.offer50WShohoHindi);
              //   couponStoreApi.getState().applyCouponToStore(coupon2);
              // }
            }
            // couponStoreApi.getState().clearCouponFromCode(couponCodes.offer50WOShohoHindi);
            // const coupon1 = hasdthBundle ?? (await konakartService.getCoupon(couponCodes.bundleCoupon_dth));
            // const coupon2 = hasBbBundle ?? (await konakartService.getCoupon(couponCodes.bundlecoupon_BB));
            // if (coupon1) couponStoreApi.getState().applyCouponToStore(coupon1);
            // if (coupon2) couponStoreApi.getState().applyCouponToStore(coupon2);
            // if(skyStarter && (fibreStarterNew || fibreStarterBoost)) changeProductQuantity(fibreStarterCredit?.id!, 1, false);
            // to remove 152BB is fibre strter/boost  removed
            // if((!fibreStarter && !fibreStarterBoost && fibreStartercreditinCart)) changeProductQuantity(fibreStarterCredit?.id!, -1, false);
            // await updateOrder(
            //   konakartService,
            //   customerId,
            //   productStoreApi.getState().getBasketItemsToAddToOrder(),
            //   couponStoreApi.getState().coupons
            // );
          }
        } else {
          // if (hasdthBundle || hasBbBundle) {
          // couponStoreApi.getState().clearCouponFromCode(couponCodes.bundleCoupon_dth);
          // couponStoreApi.getState().clearCouponFromCode(couponCodes.bundlecoupon_BB);
          // if (!hasdthonly) {
          // const coupon = await konakartService.getCoupon(couponCodes.offer50WOShohoHindi);
          // if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
          // await updateOrder(
          //   konakartService,
          //   customerId,
          //   productStoreApi.getState().getBasketItemsToAddToOrder(),
          //   couponStoreApi.getState().coupons
          // );
          // }
          // }
        }
      }
      if (BBOneMonth) {
        // remove coupon
        if (
          [fibrePro, fibreProBoost, fibreEveryday, fibreEverydayBoost].every(
            p => p?.quantity === 0 || p?.quantity === undefined
          )
        ) {
          couponStoreApi.getState().clearCouponFromCode(NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    loadCoupons();
  }, [orderLoaded, isBroadbandProduct]);

  useEffect(() => {
    if (!arrowUpfront?.quantity && orderLoaded) {
      couponStoreApi.getState().clearCouponFromCode(CreditCampaignCodes.NEW_SKY_BOX.UPFRONT_ACQUISITION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowUpfront?.quantity, orderLoaded]);

  // on mounted run the load mutation
  useEffect(() => {
    // if everything is loaded then we want to update the order to reflect - existing products in cart and coupons applied
    const updateOrderAtStart = async () => {
      try {
        const queries = parseQuery(location.search);
        const sid = queries.sid as string; // order id passed in query

        const kkCustomerId = await getTempCustomerId(konakartService, sid, isemailInURL);

        await loadOrderStore(konakartService, kkCustomerId);
        await loadProductStore(konakartService, kkCustomerId, false);

        const starter = productStoreApi.getState().products.find(p => p.sku === productSkuIds.starter.primary);

        // add starter and don't track in analytics.
        if (starter) changeProductQuantity(starter.id, 1, false);

        const orderProducts = orderStoreApi.getState().order?.orderProducts;
        if (orderProducts) {
          // add 1-month-on-us
          let coupon: T_Coupon | undefined | null;
          // If it's a VTV standalone customer, provide them with the 1 month offer without a fixed term
          if (acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone && eligibilityOfferData?.campaign) {
            coupon = await konakartService.getCoupon(eligibilityOfferData.campaign);
            // This is the coupon code for the non fixed term 1 month on us
            //coupon = has01268 ?? (await konakartService.getCoupon(couponCodes.oneMonthOnUsNoContract));
            couponStoreApi.getState().clearCouponFromCode(couponCodes.offer50WShohoHindi);
            couponStoreApi.getState().clearCouponFromCode(couponCodes.offer50WOShohoHindi);
          } else {
            // const fibreadded = orderProducts.find(p => p.categoryId === categoryIds.broadband);
            // if (fibreadded) {
            //   coupon = starter ? hasdthBundle ?? (await konakartService.getCoupon(couponCodes.bundleCoupon_dth)) : null;
            // }
            // else coupon = starter  && (starter.custom2 && starter.custom2 != undefined) ? await konakartService.getCoupon(starter.custom2) : null;
          }

          if (coupon && coupon != undefined) couponStoreApi.getState().applyCouponToStore(coupon);

          // If a session id is present in the url params, load primary box to box store from order
          if (queries.sid) addPrimaryBoxFromOrder(orderProducts);

          // removing bundle coupon  clear on initial load

          const productStore = productStoreApi.getState().products;
          const bundlePackage = productStore?.filter(p => productSkuIds.skyBundles.primary.split(',').includes(p.sku));
          const productIdsInBundle = bundlePackage?.map(bp => bp.id);
          const packageUpgradeCount = orderProducts?.filter(p => productIdsInBundle.includes(p.productId)).length;

          if (packageUpgradeCount === 0) {
            couponStoreApi.getState().clearCouponFromCode(couponCodes.bundlecoupon_for_packages!);
          } else {
            const hasbundleCoupon = couponStoreApi
              .getState()
              .coupons.find(c => c.couponCode === couponCodes.bundlecoupon_for_packages);
            if (!hasbundleCoupon) {
              const newBundleCoupon = hasbundleCoupon
                ? hasbundleCoupon
                : await konakartService.getCoupon(couponCodes.bundlecoupon_for_packages);
              if (newBundleCoupon) couponStoreApi.getState().applyCouponToStore(newBundleCoupon!);
            }
          }

          const cartProducts: T_OrderRequest[] | any =
            orderProducts && orderProducts.length > 0
              ? orderProducts?.map(p => {
                  return {
                    productId: p.productId,
                    quantity: p.quantity,
                    finalPriceIncTax: p.finalPriceIncTax,
                    downgrade: p.downgrade ?? false,
                    inPromotion: p.inPromotion,
                    currentlySubscribed: false,
                    occurrenceNumber: p.occurrenceNumber,
                    broadbandPriceHold: p.broadbandPriceHold,
                    isPhantom: p.isPhantom,
                  };
                })
              : [];

          // is bundle cart is empty redirect to home page
          if (
            location.search.length === 0 &&
            location.pathname.includes('checkout') &&
            (cartProducts.length === 0 || cartProducts.length === 1)  &&
            productStoreApi.getState().getBasketItemsToAddToOrder().length === 1
          ) {
            const skyHomeUrl = `${window?.location?.protocol}//${window.location.host.replace('get.','')}`
            window.location.href = skyHomeUrl;
          }

          // Check if we have products that need to be added OR a coupon. Then only do an update to the cart
          (orderProducts.length > 0 || couponStoreApi.getState().coupons.length > 0) ??
            updateFromOrderProducts(orderProducts);

          // case when we have stuff  Konakart, and need to update products values to match
          await updateOrder(
            konakartService,
            kkCustomerId,
            // use stored order request if there isn't a session id passed and order request cache is present
            !sid && orderRequest && orderRequest.length
              ? orderRequest
              : location.search.length === 0 && cartProducts.length > 0
              ? cartProducts
              : productStoreApi.getState().getBasketItemsToAddToOrder(),
            couponStoreApi.getState().coupons
          );

          // update order products to correctly attach box occurrence numbers
          updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
          // }

          toggleCheckFreeProductOnLoad();
          setErrorPresent(false);
        } else {
          // something went wrong as we should have the orderProducts or even empty array
          setErrorPresent(true);
        }
      } catch (errors) {
        setErrorPresent(true);
      } finally {
        setOrderLoaded(true);
      }
    };
    if (!isemailInURL || iscartDataEmpty) updateOrderAtStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibilityOfferData, isemailInURL, iscartDataEmpty]);

  useEffect(() => {
    if (isemailInURL && isloading && cartData) {
      const {
        email,
        orderDetails,
        boxDetails,
        couponDetails,
        customerDetails,
        propertyDetails,
        addressData,
        jounery,
      }: any = cartData;
      const data: any = {
        email: email,
        orderDetails: orderDetails,
        boxDetails: boxDetails,
        couponDetails: couponDetails,
        customerDetails: customerDetails,
        propertyDetails: propertyDetails,
        addressData: addressData,
        jounery: jounery,
      };
      const loadAbandonedCartData = async () => {
        try {
          const queries = parseQuery(location.search);
          const sid = queries.sid as string; // order id passed in query

          const kkCustomerId = await getTempCustomerId(konakartService, sid, isemailInURL);
          await loadOrderStore(konakartService, kkCustomerId);
          await loadProductStore(konakartService, kkCustomerId, false);

          if (data?.couponDetails?.length > 0)
            couponStoreApi.getState().applyMultipleCouponsToStore(data.couponDetails);

          if (data?.orderDetails?.orderProducts?.length > 0) {
            data.orderDetails.customerId = Number(kkCustomerId);
            updateOrderforAbandoned(data.orderDetails);
          }
          if (data?.boxDetails?.length > 0) {
            addMultipleBoxesforAbandoned(data.boxDetails);
          }
          updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
          if (
            (data?.orderDetails?.orderProducts?.length > 0 &&
              productStoreApi.getState().getBasketItemsToAddToOrder().length > 0) ||
            couponStoreApi.getState().coupons.length > 0
          ) {
            // case when we have stuff  Konakart, and need to update products values to match
            await updateOrder(
              konakartService,
              kkCustomerId,
              productStoreApi.getState().getBasketItemsToAddToOrder(),
              couponStoreApi.getState().coupons
            );
          }
        } catch {
        } finally {
          setAbandonedCartLoaded(true);
          setOrderLoaded(true);
          if (data && data?.addressData) setUpdatedValue(data.addressData);
          if (data?.customerDetails?.dateOfBirth !== undefined) {
            data.customerDetails.dateOfBirth = new Date(data.customerDetails.dateOfBirth);
          }
          setAccountDetails({
            ...accountDetails,
            yourDetail: { ...data.customerDetails },
            propertyDetail: { ...data.propertyDetails },
          });
        }
      };
      if (_.isEmpty(data.orderDetails?.orderProducts) || _.isEmpty(data.boxDetails) || _.isEmpty(data)) {
        window.location.replace(window.location.origin + '/build-my-plan/tv-packages');
        setAbandonedCartLoaded(true);
        setCartDataEmpty(true);
      }
      //if (acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone || !orderLoaded) return;
      else loadAbandonedCartData();
    }
  }, [isemailInURL, isloading, eligibilityOfferData, cartData]);

  useEffect(() => {
    if (boxeswithoutproduct.length) {
      removeBoxFromStore(boxeswithoutproduct[0]?.id || '');
    }
  }, [boxeswithoutproduct]);

  const handleAddressId = useConsumeQueryAddressId();
  // Gets `addressId` in url search params and saves the prequal data to local storage
  const handleAddressIdQuery = useQueryState('addressId', async params => {
    if (params.addressId) await handleAddressId(params.addressId);
  });

  // Get `bundle`, `skus` and `couponCode` query in search params and load products
  const handleBundleSKUQuery = useQueryState('bundle&skus&couponCode&isBundle', async params => {
    if (params.couponCode && params.couponCode !== CreditCampaignCodes.NEW_SKY_BOX.UPFRONT_ACQUISITION) {
      await queryLoadCoupons(params.couponCode);
    }

    if (params.isBundle) {
      sessionStorage.setItem('isBundle', 'true');
    }

    if (params.bundle) {
      const bundleSKUs = BUNDLES[params.bundle];
      if (bundleSKUs) await queryLoadSkus(bundleSKUs);
      bundleInURLSet(false);
    }

    if (params.skus && params.skus.split('-').length > 0) {
      const eligibileForArrowCredit =
        params.skus.split('-').includes(productSkuIds.arrowBox.primary) &&
        params.skus.split('-').includes(productSkuIds.arrowBoxOneOff.primary);

      if (
        arrowCreditOfferEnabled &&
        eligibileForArrowCredit &&
        arrowEnabled &&
        params.couponCode === CreditCampaignCodes.NEW_SKY_BOX.UPFRONT_ACQUISITION
      ) {
        const coupon = await konakartService.getCoupon(params.couponCode);
        if (coupon) couponStoreApi.getState().applyCouponToStore(coupon);
      }

      const skus = params.skus.split('-');
      await queryLoadSkus(skus);

      skusInURLSet(false);
    }
  });

  // Get `src` query in URL and add to urlParamStore
  const handleSrcQuery = useQueryState('src', params => {
    if (params?.src) {
      try {
        // Set store to default
        setSelectedSrc('WEB-ICE');
      } catch (err) {
        console.log('Failed to set src param to URL Params Store');
      }
      // Set store again to wifiGuySelectedSrc if src param has been found
      if (params.src !== null && params.src !== undefined) {
        try {
          let maxLength = 10;
          let prefix = 'WEB-';
          let trimmedSelectedSrc =
            params.src.length > maxLength ? prefix + params.src.substring(0, maxLength) : prefix + params.src;
          setSelectedSrc(trimmedSelectedSrc);
        } catch (err) {
          console.log('Failed to set src param to URL Params Store');
        }
      }
    }
  });

  // Get `sk` query in URL for stand-alone VTV migration and fetch offers
  const handleSubscriberKeyQuery = useQueryState('sk', async params => {
    if (params.sk) {
      const publicEligibilityData = await myAccountService.getPublicOffers(params.sk);
      if (publicEligibilityData && publicEligibilityData.offers) {
        setPublicEligibilityOffers(publicEligibilityData.offers);
      }
    }
  });

  useEffect(() => {
    const queryHandlers = async () => {
      if (orderLoaded && orderLoadedWithSports) {
        handleAddressIdQuery();
        handleBundleSKUQuery();
        handleSrcQuery();
        handleSubscriberKeyQuery();
        // clear URL of query params after all handlers are done
        history.replace(window.location.pathname);
      }
    };
    queryHandlers();
    // eslint-disable-next-line
  }, [orderLoaded, orderLoadedWithSports]);

  useEffect(() => {
    if (orderLoaded) {
      updateFromOrderProducts(orderStoreApi.getState().order?.orderProducts ?? []);
      // update products in boxes store from product store
      updateBoxProducts(productStoreApi.getState().products);
    }
    // eslint-disable-next-line
  }, [order, orderLoaded]);

  // Handle Iddle GA Sessions
  const handleOnIdle = () => {
    setActiveGASession(!isIdle);
  };

  const { isIdle } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
  });

  if (location.pathname === '/build-my-plan/tv-packages' && sessionStorage.getItem('isBundle')) {
    sessionStorage.removeItem('isBundle');
  }
  // only true if we catch an error on load - for konakart
  if (errorPresent)
    return (
      <>
        <ErrorContainer
          headerText={pageErrorMessage.header}
          lineOne={pageErrorMessage.lineOne}
          lineTwo={pageErrorMessage.lineTwo}
        />
      </>
    );

  if (
    !(customerId && initializedProductStore && initializedOrderStore && !isTermsCondtionLoading) ||
    bundleInURL ||
    skusInURL
  )
    return (
      <div className="h-128">
        <BlockLoader />
      </div>
    );

  return (
    <Route path="/">
      <NotificationManager notificationsEndpoint={LIFERAY_CONNECT_ENDPOINT} domain={DOMAIN} />
      <Switch>
        {([...routes, ...skyAppsRoutes] ?? [])?.map(({ path, exact, component }) => (
          <Route key={path} exact={exact} path={path} component={component} />
        ))}
        <Route exact={false} path={'/checkout'} component={Checkout} />
        <Route exact={false} path={'/confirmation'} component={Confirmation} />
        {(termsConditionMenu ?? [])?.map(({ slug }) => (
          <Route
            key={slug!}
            exact={true}
            path={`/${slug}`}
            render={() => <TermsAndConditionScreen menus={termsConditionMenu as MenuItemsType[]} />}
          />
        ))}
        <Redirect to={routes[0].path + location.search} from="/" />
      </Switch>
    </Route>
  );
};

function App() {
  const [isMaintenanceMode] = useSplitIO(SPLITIO_KEY.MAINTENANCE_MODE_ICE);
  const [isFreshChatEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_LIVECHAT_CART);
  const [isSurvicateEanbled] = useSplitIO(SPLITIO_KEY.ENABLE_SURVICATE);

  const { isAuthenticated, login, logout } = useAuth0();
  const { data: profile } = myAccountSWR.useProfile();
  const { pathname } = useLocation();

  const navLinks = useNavStore(s => s.navLinks);
  let load = useNavStore(s => s.loadLinks);

  const { updateEmail } = useAccountDetails(addressService);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const loggedOutEmail = urlParams.get('loggedoutuser') ?? '';
    updateEmail(atob(loggedOutEmail));
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFreshChatEnabled) {
    initFC({});
  } else {
    disableFC();
  }

  useEffect(() => {
    console.log({ isSurvicateEanbled });
    const eleId = 'survicate';
    if (isSurvicateEanbled) {
      (function(w) {
        var s = document.createElement('script');
        s.id = eleId;
        s.src = 'https://survey.survicate.com/workspaces/e78f97f8d7583351bf5dd2cd9cd4393f/web_surveys.js';
        s.async = true;
        var e = document.getElementsByTagName('script')[0];
        e.parentNode!.insertBefore(s, e);
      })(window);
    } else {
      document.getElementById(eleId)?.remove();
    }
  }, [isSurvicateEanbled]);
  const cartSummary = pathname !== '/confirmation' ? <CartSummary /> : <></>;
  return (
    <>
      <Header
        links={navLinks}
        isSignedIn={isAuthenticated}
        name={profile?.displayName || profile?.firstName}
        SessionClickHandler={isAuthenticated ? logout : login}
        cartURL={ICE_URL!}
        cartSummary={cartSummary}
      />
      {isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <>
          {/* <Nav /> */}
          <IEBanner>
            <Body />
          </IEBanner>
        </>
      )}
      <Footer links={navLinks} />
      <ToastContainer />
    </>
  );
}

export default App;
