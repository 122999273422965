import { Button, Login, RunningTotalInner } from '@sky-tv-group/components';
import { addMonths, format } from 'date-fns';

import {
  categoryIds,
  CouponTypeValue,
  ELIGIBILITY_ACQUISITION_TYPE,
  formatMoney,
  PACKAGE_TYPE,
  useAnalytics,
  useCartContainer,
  useEligibilityOffers,
  useOrderStore,
  useOrderSummary,
  useProductInCart,
  useTotals,
  unformattedTelPhoneNumber,
  segment,
} from '@sky-tv-group/shared';
import { Loader } from '@sky-tv-group/components';
import React, { useEffect, useRef, useState } from 'react';
import { ACCOUNT_URL } from '../../config';
import history from '../../history';
import { konakartService, myAccountSWR } from '../../services';
import { StickyControlPanel } from './stickyControlPanel';
import { useAcquisitionJourneyState } from '../../provider/AcquisitionJourney';
import { BackButton } from '../../components/back-button';
import { BreadcrumbContainer } from '../../components/BreadcrumbContainer';

export let Review = () => {
  const { broadbandPhoneProducts } = useProductInCart();
  const { progressBar } = useAcquisitionJourneyState();
  const { couponDuration } = useCartContainer(konakartService, CouponTypeValue.Acquisition);

  const couponExpireTimeStr = format(addMonths(new Date(), couponDuration ? +couponDuration : 0), 'do MMMM yyyy');

  const monthlyFeeCategories = [
    categoryIds.broadband,
    categoryIds.broadbandDevices,
    categoryIds.package,
    categoryIds.packageUpgrade,
    categoryIds.box,
    categoryIds.special,
    categoryIds.voice,
    categoryIds.voiceAddons,
    categoryIds.voiceCrossCountry,
    categoryIds.additionalCharges,
    categoryIds.hindiChannels,
    categoryIds.multiroom,
  ];
  const Broadband = 'Broadband';
  const tvDevices = 'TV Devices';
  const tv = 'TV';
  const oneOffCharges = 'One-off Charges';

  const { coupons } = useOrderSummary(monthlyFeeCategories, konakartService);
  const { acquisitionType } = useEligibilityOffers();
  const totals = useTotals(myAccountSWR);
  const { checkoutStarted } = useAnalytics();
  const order = useOrderStore(s => s.order);
  const { isOrderPending } = useOrderStore();
  const coupon = coupons[0];
  const broadbandIsIncludedInCart = broadbandPhoneProducts!.length > 0;

  let stickyRef = useRef<HTMLDivElement>(null) as any;
  function handleStickyBarScroll() {
    let footerElement = document.querySelector('#footerTermsAndCond') as any;
    if (stickyRef.current) {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        footerElement.style.marginBottom = stickyRef.current.offsetHeight + 'px';
      } else {
        footerElement.style.marginBottom = '0px';
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleStickyBarScroll);
    return () => {
      window.removeEventListener('scroll', handleStickyBarScroll);
    };
  }, []);
  const handleBack = () => {
    if (acquisitionType && acquisitionType === ELIGIBILITY_ACQUISITION_TYPE.VTV_Standalone) {
      history.push('/build-my-plan/sky-box');
    } else {
      history.push('/build-my-plan/broadband');
    }
  };

  const handleNext = () => {
    if (order) {
      broadbandIsIncludedInCart
        ? checkoutStarted(order, false, coupon, '', false, PACKAGE_TYPE.DTH_BROADBAND)
        : checkoutStarted(order, false, coupon, '', false, PACKAGE_TYPE.DTH);
    }
    history.push('/checkout/account-form');
  };

  return (
    <div className="container-fluid mx-auto -my-4">
      <div className="flex flex-col lg:flex-row">
        <div
          className={`w-full ${
            window.location.pathname === '/build-my-plan/checkout' && sessionStorage.getItem('isBundle') === 'true'
              ? 'w-full'
              : 'lg:w-4/5'
          }`}>
          <div>
            <BreadcrumbContainer progressBarStatus={false} />
            {isOrderPending && (
              <div className=" w-full h-full">
                <Loader bgOpacity={true} />
              </div>
            )}
            <div className="container m-auto " data-testid="review">
              <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start py-10 lg:px-16 md:w-100">
                {sessionStorage.getItem('isBundle') ? (
                  ''
                ) : (
                  <BackButton
                    buttontext="Go back to Broadband"
                    onClick={() => history.push('/build-my-plan/broadband')}
                  />
                )}
              </div>
              <div className="lg:px-16 md:w-100 md:w-full lg:ml-1 md:text-left">
                <h2 className="px-8 md:px-0 py-3 text-center  pb-2 text-navy mb-8">
                  <span className="sky-h3-black md:sky-h4-black">Review</span>
                  <span className="sky-h3-reg md:sky-h4-reg ml-2">Your Package:</span>
                </h2>

                <div className="md:flex flex-col md:flex-row lg:items-start md:pb-12">
                  <div className="flex-1">
                    <RunningTotalInner
                      cartType={CouponTypeValue.Acquisition}
                      showNew={true}
                      show={true}
                      konakartService={konakartService}
                      myAccountSWR={myAccountSWR}
                      handleNext={handleNext}
                    />
                    {!(window.location.pathname === '/build-my-plan/checkout' &&
                      sessionStorage.getItem('isBundle') === 'true') &&
                      <div className="block fixed min-w-full bottom-0 lg:static z-50 md:static left-0" ref={stickyRef}>
                      <div
                        className={`flex justify-between md:p-12 lg:p-12  min-w-full bottom-0 z-50 lg:shadow-none lg:hidden md:shadow-none lg:rounded-none md:rounded-none sticky-custom bg-white left-0 p-5`}>
                        <Button
                          data-testid="next-to-checkout-button"
                          colorMode={isOrderPending ? 'disabled' : 'pure'}
                          variant="primary"
                          onClick={handleNext}
                          disabled={isOrderPending}
                          className="min-w-full ">
                          Next: About You
                        </Button>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sessionStorage.getItem('isBundle') !== 'true' && (
          <div className="w-full lg:w-1/5 my-0 lg:sticky lg:stick-to-top lg:right-0 ">
            <StickyControlPanel totals={totals} handleBack={handleBack} handleNext={handleNext} />
          </div>
        )}
      </div>
      <div className="text-center mb-8">
        Need more help? Call us on{' '}
        <a
          className="text-blue-pure underline"
          href={`tel:${unformattedTelPhoneNumber}`}>{`${unformattedTelPhoneNumber}`}</a>
      </div>
    </div>
  );
};
